body[data-progressbar="busy"] .progressbar-spinner {
  animation: spin 1s linear infinite;
  pointer-events: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}