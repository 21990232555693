.browser_upgrade {
  padding: 40px 20px;
  background: #f9e4e4;
}

.browser_upgrade .browsers {
  margin-top: 20px;
  width: 100%;
}
.browser_upgrade .browser_image {
  width: 40px;
  height: 40px;
}
.browser_upgrade td {
  width: 20%;
  text-align: center;
}
